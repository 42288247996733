<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Treatment Entry
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-data-entry-list" method="post" @submit="addTreatMentList">
				<b-card>
					<b-row>
						<!-- <b-col md="6">
							<b-form-group>
								<template v-slot:label> Date of Treatment<span class="text-danger">*</span> </template>
<b-form-datepicker name="Date of Treatment" v-model="FormData.purchase_date" format="YYYY-MM-DD" menu-class="w-100"
	calendar-width="100%" class="mb-1" />
</b-form-group>
</b-col> -->
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Sign/Symptoms<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<ValidationProvider #default="{ errors }" name="Symptoms" :rules="{
										required: true

									}">
										<v-select v-model="FormData.treatment_symptoms" :options="symptomList" multiple label="name"
											clearable:false :reduce="(e) => e.id" :max="3" />
										<small class="text-danger">{{ errors[0] }}</small>
									</ValidationProvider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Medicine<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Select Medicine" rules="required">
										<v-select v-model="selectedMedicineList" :options="medicineList" label="name" clearable:false
											@input="setRouteType" />
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Batch No<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Select Batch No" rules="required">
										<v-select v-model="selectedBatchList" :options="batchList" label="batch_no" clearable:false
											@input="setBatchData" />
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label>Route</template>
								<div class="form-label-group">
									<b-form-input v-model="routeType" label="routeType" type="text" :value="routeType" disabled />
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> No. of Birds<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="No. of Birds" rules="required">
										<b-form-input v-model="FormData.birds_quantity" placeholder="No. of Birds" type="text"
											name="No. of Birds" />
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label>
									Medicine Administrated (Pack)<span class="text-danger">*</span>
								</template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Medicine Administrated (Pack)" rules="required">
										<b-form-input v-model="FormData.medicine_administered" placeholder="Medicine Administrated (Pack)"
											type="text" name="total_stock" />
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group class="mb-1">
								<template v-slot:label> Select Image </template>
								<validation-provider #default="{ errors }" name="Image" :rules="{
									mimes: ['image/jpeg', 'image/png'],
									size: 2048,
								}">
									<b-row>
										<b-col class="col-9">
											<b-form-file v-model="FormData.treatment_image" accept=".jpg, .png, .gif"
												@change="onFileChange" />
										</b-col>
										<b-col class="col-3">
											<b-button variant="outline-primary" class="reset-btn" @click="resetImage">X</b-button>
										</b-col>
									</b-row>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
							<b-card-text>Allowed JPG, GIF or PNG. Max size of 2 Mb</b-card-text>
							<div v-if="url">
								<div class="profile-image">
									<img :src="url" height="90" style="max-width: 100%" />
								</div>
							</div>
						</b-col>
						<!-- <b-col md="6">
              <b-form-group class="mb-1">
                <template v-slot:label> Profile Image </template>
                <b-form-file
                  v-model="FormData.treatment_image"
                  plain
                  @change="onFileChange"
                />
              </b-form-group>
              <div v-if="url">
                <div class="profile-image">
                  <img :src="url" height="90" />
                  <div class="remove-image">
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="deleteIcon"
                      @click="resetImage"
                    />
                  </div>
                </div>
              </div>
            </b-col> -->
						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3">
								Submit
							</b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			required,
			selected: null,
			url: null,
			medicine_id: null,
			user_image: null,
			FormData: {
				purchase_date: null,
				birds_quantity: null,
				pack_size: null,
				pack_quantity: null,
				treatment_image: null,
			},
			selectRouteType: null,
			routeType: null,
			selectedSymptomList: null,
			selectedMedicineList: null,
			selectedBatchList: null,
			routeList: [],
			batchList: [],
			medicineList: [],
			symptomList: [],
			treatmentList: [],
			pack_size: null,
			rate_per_pack: null,
		};
	},
	created: function () {
		this.flockId = this.$route.params.flockId;
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
		this.getSymptomsList();
		this.getMedicine();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		setRouteType(event) {
			this.routeType = event.route.name;
			this.route_type_id = event.route.id;
			this.medicine_id = event.id;
			this.getBatchListForSelectedMedicine(event.id);
		},
		setBatchData(event) {
			this.batch_no = event.batch_no;
			this.pack_size = event.pack_size;
			this.rate_per_pack = event.rate_per_pack;
		},
		getSymptomsList() {
			axiosIns
				.get(`web/symptoms-list`)
				.then((response) => {
					this.symptomList = response.data.symptom_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getMedicine() {
			axiosIns
				.get(`web/get-all-medicines`)
				.then((response) => {
					this.medicineList = response.data.medicine_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getBatchListForSelectedMedicine(id) {
			axiosIns
				.get(`web/farm/${this.farmId}/medicine/${id}/batch-list`)
				.then((response) => {
					this.batchList = response.data.batch_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		addTreatMentList(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					let symptoms = JSON.stringify(this.FormData.treatment_symptoms);
					data.append("medicine_id", this.medicine_id);
					data.append("symptoms", symptoms);
					data.append("batch_no", this.batch_no);
					data.append("route_type_id", this.route_type_id);
					data.append("treatment_image", this.FormData.treatment_image);
					data.append("birds_quantity", this.FormData.birds_quantity);
					data.append("medicine_administered", this.FormData.medicine_administered);
					data.append("pack_size", this.pack_size);
					data.append("rate_per_pack", this.rate_per_pack);
					axiosIns
						.post(`web/farm/${this.farmId}/shed/${this.shedId}/flock/${this.flockId}/treatment-entry`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								path: `/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
		onFileChange(e) {
			const file = e.target.files[0];
			this.url = URL.createObjectURL(file);
		},
		resetImage() {
			this.url = "";
			this.treatment_image = null;
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}

.profile-image {
	position: relative;
	border: 1px dashed #d9d9d9;
	height: 100px;
	width: 100px;
	padding: 5px 5px;
	text-align: center;
	border-radius: 5px;
	margin-top: 2rem;
}

.deleteIcon {
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.remove-image {
	position: absolute;
	top: -7px;
	right: -10px;
	box-shadow: 0 9px 16px 0 rgba(24, 28, 50, 0.25) !important;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	color: #3f4254;
	background-color: #fff;
	border-color: #fff;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
</style>